@font-face {
    font-family: 'SEGA LOGO FONT';
    font-style: normal;
    font-weight: normal;
    src: local('SEGA LOGO FONT'), url('SEGA.woff') format('woff');
    }

    h1{
        font-family: "SEGA LOGO FONT ";
        font-weight: 300;
        color: #0ccac4;
    }